import React from 'react'
import './fieldset.sass'

export default function Fieldset(props) {
  let { fields = [], columns = 2 } = props

  let classes = ['q-fieldset']
  if (columns) classes.push(`q-fieldset--columns-${columns}`)

  return (
    <div className={classes.join(' ')}>
      <div className="q-fieldset-fields">{fields}</div>
    </div>
  )
}
