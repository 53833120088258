// Force build counter: 4
import 'promise-polyfill/src/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './root'
import './sass/app.sass'

const render = () => ReactDOM.render(<Root />, document.getElementById('app'))

if (
  'Map' in window &&
  'forEach' in NodeList.prototype &&
  'startsWith' in String.prototype &&
  'endsWith' in String.prototype &&
  'includes' in String.prototype &&
  'includes' in Array.prototype &&
  'assign' in Object &&
  'entries' in Object &&
  'keys' in Object
) {
  render()
} else {
  import('./polyfills').then(render)
}
