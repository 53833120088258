import React from 'react'
import VideoIntro from './videoIntro'
import './content.sass'

export default function ContentField(props) {
  let { content = {}, lang, videoIntro = false } = props

  if (videoIntro) return <VideoIntro {...props} />

  content = content[lang] || false

  if (!content) return null

  return (
    <div className="q-content">
      <div className="q-content-body" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  )
}
