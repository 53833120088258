import styled from '@emotion/styled'
import React from 'react'
import { getThemeVar } from '../theme'
import './action.sass'

export default function SurveyAction(props) {
  let { text, icon, disabled = false, type = 'submit', surveyInfo: { theme = {} } = {} } = props

  const style = getThemeVar('style', theme.style)
  const primary = getThemeVar('primaryColor', theme.primaryColor)

  let actionBackground = style === 'color' ? primary : '#ffffff'

  const Action = styled.div`
    .page--default & {
      background-color: ${actionBackground};
      ${style === 'contrast'
        ? `
        border-top: 1px solid #dddddd;
      `
        : ''}
    }
  `

  const defaultStyle =
    style === 'color'
      ? /*css*/ `
  .page--default & {
    color: ${primary};
  }

  .page--default &:hover {
    color: #fff;
    background: ${primary};
    opacity: 1;
  }
  `
      : /*css*/ `
  .page--default & {
    background: ${primary};
    border-color: ${primary};
    color: #ffffff;
    box-shadow: 0 1px 6px rgba(0,0,0,0.1)
  }
  .page--default &:hover {
    background: #fff;
    color: ${primary};
    opacity: 1;
  }
  `

  const frameStyle = /*css*/ `
    .page--frame & {
      background: ${primary};
      border-color: ${primary};
      color: #ffffff;
    }

    .page--frame &:hover {
      background: #fff;
      color: ${primary};
      opacity: 1;
    }
  `

  const buttonStyles = defaultStyle + frameStyle

  const Button = styled.button(buttonStyles)

  return (
    <Action className="survey-form-submit">
      <Button className="survey-form-submit-button" type={type} disabled={disabled}>
        <i className={`fa fa-${icon}`} />
        <span>{text}</span>
      </Button>
    </Action>
  )
}
