import { List, Map } from 'immutable'
import { isMobile } from 'mobile-device-detect'
const HAS_CAPTURE_SUPPORT =
  (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && window.MediaRecorder) || isMobile

export function groupConditions(conditions) {
  let groups = []
  let group = []

  for (let i = 0; i < conditions.length; i++) {
    let condition = conditions[i]
    let { join = 'and' } = condition

    if (i && join === 'and') {
      groups.push(group)
      group = []
    }

    group.push(condition)
  }

  if (group.length) groups.push(group)

  return groups
}

let operatorFunctions = {
  istrue: value => value === true,
  isfalse: value => value === false,
  isnull: value => (List.isList(value) ? value.size === 0 : value === false || value === '' || value === null),
  notnull: value => (List.isList(value) ? value.size > 0 : value !== false && value !== '' && value !== null),
  is: (value, test) => (List.isList(value) ? value.contains(test) : value == test),
  isnot: (value, test) => (List.isList(value) ? !value.contains(test) : value != test),
  greaterthan: (value, test) => parseInt(value) > parseInt(test),
  lessthan: (value, test) => parseInt(value) < parseInt(test),
}

export function resolveCondition(condition = {}, values = Map(), features = []) {
  let { field = false, feature = false, operator, test = '', captureSupport = false } = condition

  if (captureSupport) return HAS_CAPTURE_SUPPORT

  // If condition is tested against feature, then just check if features array contains that feature
  if (feature) return features.includes(feature)

  if (typeof field !== 'string') return false

  let value = values.getIn(field.split('.'), false)
  operator = operatorFunctions[operator] || false
  if (typeof operator !== 'function') return false

  return operator(value, test)
}

export function resolveConditions(conditions, values, features = []) {
  let groups = groupConditions(conditions)

  for (let i = 0; i < groups.length; i++) {
    let group = groups[i]

    let test = group.reduce((test, c) => (resolveCondition(c, values, features) ? test + 1 : test), 0)

    if (!test) return false
  }

  return true
}
