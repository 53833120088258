import React from 'react'
import { getThemeVar } from './theme'

export default function Footer(props) {
  let { t, theme } = props

  let backgroundType = getThemeVar('pageBackgroundColorType', theme.pageBackgroundColorType)

  const logo =
    backgroundType === 'light'
      ? 'https://d2nce6johdc51d.cloudfront.net/img/tm-badge-black.svg'
      : 'https://d2nce6johdc51d.cloudfront.net/img/tm-badge.svg'

  return (
    <div className={`page-footer page-footer--${backgroundType}`}>
      <div className="page-footer-inner">
        <div className="page-footer-text">{t('footer.providedBy', 'Provided by')}</div>
        <div className="page-footer-logo">
          <a
            href="https://www.trustmary.com/?utm_source=trustmary-survey&utm_medium=survey-bottom-logo-link&utm_campaign=loop"
            target="_blank">
            <img src={logo} alt="Trustmary" />
          </a>
        </div>
      </div>
      <div className="page-footer-info">
        {t('footer.createSurveyText', 'Do you want to get testimonials from your customers?')} <br />
        <a
          href="https://www.trustmary.com/?utm_source=trustmary-survey&utm_medium=survey-bottom-link&utm_campaign=loop"
          target="_blank">
          {t('footer.createSurveyLink', 'Create a free testimonial survey in 5 minutes.')}
        </a>
      </div>
    </div>
  )
}
