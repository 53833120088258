import { List } from 'immutable'
import React from 'react'
import { ThemeSelectIcon } from './checkbox'
import './checkboxes'

export default function Checkboxes({ name, options = [], value, onChange, lang, themeVar }) {
  if (!List.isList(value)) value = List()

  return (
    <div className="q-input q-input--checkboxes">
      {options.map(({ value: optionValue, label = {} }) => {
        let classes = ['q-checkbox']
        let isChecked = value.contains(optionValue)

        if (isChecked) classes.push('q-checkbox--selected')

        label = label[lang] || ''

        return (
          <button
            className={classes.join(' ')}
            onClick={e => {
              e.preventDefault()
              onChange({
                name,
                value: value.contains(optionValue) ? value.remove(value.indexOf(optionValue)) : value.push(optionValue),
              })
            }}
            key={optionValue}>
            <span className="q-checkbox-icon-wrapper">
              <ThemeSelectIcon className="q-checkbox-icon" color={themeVar('primaryColor')}>
                {isChecked ? <i className="fa fa-check" /> : null}
              </ThemeSelectIcon>
            </span>
            <span className="q-checkbox-label">{label}</span>
          </button>
        )
      })}
    </div>
  )
}
