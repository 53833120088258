import styled from '@emotion/styled'
import { List } from 'immutable'
import { isMobile } from 'mobile-device-detect'
import React, { useEffect, useRef, useState } from 'react'
import './video.sass'

const CaptureActionButton = styled.button`
  background: #fff;
  color: #1e2430;
  i.fa {
    color: ${props => props.main};
  }
  &:hover {
    background: ${props => props.main};
    border-color: ${props => props.main};
    color: #fff;
  }
    &:hover i.fa {
    color: #fff;
  }
    &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
  }
`

const { MediaRecorder = {} } = window

const MIME_TYPE =
  typeof MediaRecorder.isTypeSupported !== 'function'
    ? 'video/mp4'
    : ['video/webm;codecs=vp8,opus', 'video/mp4'].reduce(
        (supported, type) => (supported ? supported : MediaRecorder.isTypeSupported(type) ? type : false),
        false
      )

const FILE_EXTENSION = MIME_TYPE === 'video/mp4' ? '.mp4' : '.webm'

function Permission({ t, setStream, onError, constraints, gotPermission }) {
  const onSuccess = stream => {
    setStream(stream)
  }

  const onFailure = err => {
    console.log(err)
    onError(err.message)
  }
  useEffect(() => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia(constraints).then(onSuccess).catch(onFailure)
    } else if (navigator.getUserMedia) {
      navigator.getUserMedia(
        //legacy
        constraints,
        onSuccess,
        onFailure
      )
    } else {
      onError(`Can't find a video device`)
    }
  }, [constraints])

  return (
    <div className="q-capture-media-action q-capture-media-action--permission">
      {gotPermission ? (
        <div className="q-capture-media-action-title">{t('video.deviceLoading')}</div>
      ) : (
        <div className="q-capture-media-action-title">{t('capture.video.permissionTitle')}</div>
      )}
    </div>
  )
}

function CaptureStart({ t, startCapture, countdown, setCountdown }) {
  useEffect(() => {
    if (countdown === false) return

    setTimeout(() => {
      let nextCount = countdown - 1

      if (nextCount) setCountdown(nextCount)
      else startCapture()
    }, 1000)
  }, [countdown])

  let classes = ['q-capture-media-action', 'q-capture-media-action--record']
  if (countdown !== false) classes.push('q-capture-media-action--counting')

  return (
    <div className={classes.join(' ')}>
      <div className="q-capture-media-action-button">
        <button onClick={e => e.preventDefault() & setCountdown(3)} disabled={countdown !== false}>
          {countdown === false ? (
            <i className="fa fa-video" />
          ) : (
            <span className="q-capture-media-countdown">{countdown}</span>
          )}
        </button>
      </div>
    </div>
  )
}

// function AudioDetecter({t, stream}) {
//   let analyser = useRef()
//   let dataArray = useRef()

//   useEffect(() => {
//     if(!AudioContext) return
//     if(!stream.current) return

//     let context = new AudioContext()
//     analyser.current = context.createAnalyser()

//     context.createMediaStreamSource(stream.current)
//     analyser.current.connect(context.destination)
//     analyser.current.minDecibels = -90
//     analyser.current.maxDecibels = -10
//     analyser.current.smoothingTimeConstant = 0.85
//     analyser.current.fftSize = 256

//     let bufferLength = analyser.current.frequencyBinCount
//     dataArray.current = new Uint8Array(bufferLength)

//     const getVolume = () => {
//       requestAnimationFrame(getVolume)

//       analyser.current.getByteFrequencyData(dataArray.current)

//       for(let i = 0; i < bufferLength; i++) {
//         let val = dataArray.current[i]
//         if(val)
//           console.log(val)
//       }
//     }

//     getVolume()

//   }, [])

//   return null
// }

function DeviceSelector({ devices, onDeviceSelected, anchorContent }) {
  const [menuOpen, setMenuOpen] = useState(false)

  //let backgroundType = getThemeVar('pageBackgroundColorType', theme.pageBackgroundColorType)

  return (
    <div className={`q-capture-deviceswitcher q-capture-deviceswitcher--dark`}>
      <div className="q-capture-deviceswitcher-select">
        <button onClick={e => e.preventDefault() & setMenuOpen(true)}>{anchorContent}</button>
        {menuOpen && (
          <>
            <div className="q-capture-deviceswitcher-overlay" onClick={e => e.preventDefault() & setMenuOpen(false)} />
            <div className="q-capture-deviceswitcher-menu">
              {devices.map(device => {
                return (
                  <>
                    <button
                      disabled={device.selected}
                      key={device.deviceId}
                      onClick={e => {
                        e.preventDefault()
                        if (onDeviceSelected) onDeviceSelected(device)
                      }}>
                      {device.label + (device.selected ? ' ✓' : '')}
                    </button>
                  </>
                )
              })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const getOrientation = () => {
  return window.innerWidth > window.innerHeight ? 'portrait' : 'landscape'
}

function Capture({ onChange, onCancel, onError, t }) {
  let videoRef = useRef(null)
  let mediaRecorder = useRef(null)
  let mediaChunk = useRef([])
  let stream = useRef(null)
  let orientation = useRef(getOrientation())

  let [error, setError] = useState(null) //null = no error, string = error
  let [gotPermission, setGotPermission] = useState(false) //Stays true after permission granted for the first time
  let [askPermission, setAskPermission] = useState(true) //Can be toggled to change device or other constraints
  let [recording, setRecording] = useState(false)
  let [videoDevices, setVideoDevices] = useState([])
  let [audioDevices, setAudioDevices] = useState([])
  let [deviceConstraints, setDeviceConstraints] = useState({
    video: {
      width: { max: 1920, ideal: 1280 },
      height: { max: 1080, ideal: 720 },
    },
    audio: true,
  })
  const [countdown, setCountdown] = useState(false)

  const setStream = rawStream => {
    const trackSettings = rawStream.getTracks().map(track => track.getSettings())
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        const videoDevices = devices.filter(device => device.kind === 'videoinput')
        videoDevices.forEach(
          device =>
            (device.selected = undefined !== trackSettings.find(settings => settings.deviceId === device.deviceId))
        )
        setVideoDevices(videoDevices)
        const audioDevices = devices.filter(device => device.kind === 'audioinput')
        audioDevices.forEach(
          device =>
            (device.selected = undefined !== trackSettings.find(settings => settings.deviceId === device.deviceId))
        )
        setAudioDevices(audioDevices)
      })
    } else {
      console.log('enumerateDevices() not supported.')
      //We continue without the option to select camera/microphone
    }
    stream.current = rawStream
    videoRef.current.volume = 0
    videoRef.current.srcObject = rawStream
    mediaRecorder.current = new MediaRecorder(stream.current, {
      mimeType: MIME_TYPE,
    })

    mediaRecorder.current.ondataavailable = ev => {
      if (ev.data && ev.data.size > 0) mediaChunk.current.push(ev.data)
    }

    setAskPermission(false)
    setGotPermission(true)
  }

  const startCapture = () => {
    if (recording) return console.log('Already recording')
    mediaChunk.current = []
    mediaRecorder.current.start(10)
    setRecording(true)
  }

  const saveRecording = () => {
    mediaRecorder.current.stop()

    let blob = new Blob(mediaChunk.current, { type: MIME_TYPE })
    blob.lastModifiedDate = new Date()
    blob.name = `recording.${FILE_EXTENSION}`
    onChange(blob)
  }

  const stopStream = () => {
    if (!stream.current) return
    stream.current.stop && stream.current.stop()
    stream.current.getTracks().forEach(track => track.stop())
    stream.current = null
  }
  useEffect(() => {
    const resizeListener = () => {
      const newOrientation = getOrientation()
      if (!askPermission && !recording && !countdown && orientation.current !== newOrientation) {
        orientation.current = newOrientation
        stopStream()
        setAskPermission(true)
      }
    }

    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [askPermission, recording, !countdown])

  useEffect(() => {
    return () => {
      stopStream()
    }
  }, [])

  const onPermissionError = reason => {
    setError(reason)
    if (onError) onError(reason)
  }

  return (
    <div class="q-capture-media-wrapper">
      {!askPermission && !recording && !countdown && (
        <div className="q-capture-device-selectors">
          {videoDevices.length > 0 && (
            <DeviceSelector
              anchorContent={
                <>
                  <i className="fa fa-camera" />
                  {t('video.selectCamera')}
                </>
              }
              devices={videoDevices}
              onDeviceSelected={device => {
                stopStream()
                setDeviceConstraints({
                  audio: deviceConstraints.audio,
                  video: { ...deviceConstraints.video, deviceId: device.deviceId },
                })
                setAskPermission(true)
              }}
            />
          )}
          {audioDevices.length > 0 && (
            <DeviceSelector
              anchorContent={
                <>
                  <i className="fa fa-microphone" />
                  {t('video.selectMicrophone')}
                </>
              }
              devices={audioDevices}
              onDeviceSelected={device => {
                stopStream()
                setDeviceConstraints({
                  audio: { deviceId: device.deviceId },
                  video: deviceConstraints.video,
                })
                setAskPermission(true)
              }}
            />
          )}
        </div>
      )}
      <div className="q-capture-media">
        <video playsInline autoPlay muted ref={videoRef} />
        {error ? (
          <div class="q-capture-media-error">
            <div className="q-capture-media-error-inner">{t('error')}</div>
          </div>
        ) : askPermission ? (
          <Permission
            t={t}
            setStream={setStream}
            onError={onPermissionError}
            constraints={deviceConstraints}
            gotPermission={gotPermission}
          />
        ) : !recording ? (
          <CaptureStart startCapture={startCapture} t={t} countdown={countdown} setCountdown={setCountdown} />
        ) : (
          <div className="q-capture-media-action q-capture-media-action--stop">
            <div className="q-capture-media-action-button">
              <button onClick={e => e.preventDefault() & saveRecording()}>
                <i className="fa fa-save" />
              </button>
            </div>
          </div>
        )}
        <div className="q-capture-media-close">
          <button onClick={e => e.preventDefault() & onCancel()}>
            <i className="fa fa-times" />
          </button>
        </div>
      </div>
    </div>
  )
}

function VideoInfo({ canRecord, captureOpen, isMobile, video, t }) {
  if (captureOpen)
    return (
      <div className="q-video-field-content">
        <h3>
          <i className="fa fa-video" />
          {t('video.record.title')}
        </h3>
        <ul>
          {t('video.record.list')
            .split('\n')
            .map(t => (
              <li key={t}>{t.trim()}</li>
            ))}
        </ul>
      </div>
    )

  if (video)
    return (
      <div className="q-video-field-content q-video-field-content--success">
        <h3>
          <i className="fa fa-check" />
          {t('video.done.title')}
        </h3>
        <ul>
          {t('video.done.list')
            .split('\n')
            .map(t => (
              <li key={t}>{t.trim()}</li>
            ))}
        </ul>
      </div>
    )

  if (!canRecord)
    return (
      <div className="q-video-field-content">
        <h3>{t('video.start.titleUpload')}</h3>
        <ol>
          {t('video.start.listUpload')
            .split('\n')
            .map(t => (
              <li key={t}>{t.trim()}</li>
            ))}
        </ol>
      </div>
    )

  return (
    <div className="q-video-field-content">
      <h3>{t('video.start.title')}</h3>
      <ol>
        {t('video.start.list')
          .split('\n')
          .map(t => (
            <li key={t}>{t.trim()}</li>
          ))}
      </ol>
      {!isMobile ? (
        <>
          <h3>{t('video.start.titleUpload')}</h3>
          <ol>
            {t('video.start.listUpload')
              .split('\n')
              .map(t => (
                <li key={t}>{t.trim()}</li>
              ))}
          </ol>
        </>
      ) : null}
    </div>
  )
}

export default function VideoField(props) {
  let { features, name: videoName, value: videoFile, onChange, onFileChange, themeColors, t } = props

  let [captureOpen, setCaptureOpen] = useState(false)
  let [captureError, setCaptureError] = useState(null) //null = no error, string = error reason
  // let videoDisabled = features.indexOf('videoField') === -1
  // if(videoDisabled)
  //   return null

  const onCaptureError = reason => {
    setCaptureError(reason)
    setCaptureOpen(false)
  }

  if (!List.isList(videoFile)) {
    if (typeof videoFile === 'string' && videoFile.trim() !== '') {
      videoFile = List().push({ preview: `${FILE_ROOT}/${videoFile}` })
    } else {
      videoFile = List()
    }
  }

  let defaultData = {
    name: videoName,
    fileType: '*',
    multiple: false,
    maxSize: 1024 * 1024 * 200,
  }

  let canRecord = navigator.mediaDevices && navigator.mediaDevices.getUserMedia && window.MediaRecorder && MIME_TYPE

  let video = videoFile.first()

  return (
    <div className="q-video-field-wrapper">
      <div className="q-video-field">
        <div className={`q-video-field-video q-video-field-video--${video ? 'preview' : 'capture'}`}>
          {video ? (
            <video src={video.preview} playsInline controls />
          ) : canRecord ? (
            <div className="q-capture-wrapper">
              {captureOpen ? (
                <Capture
                  onChange={file => {
                    onFileChange({ value: [file], ...defaultData })
                    setCaptureOpen(false)
                  }}
                  onCancel={() => setCaptureOpen(false)}
                  t={t}
                  onError={onCaptureError}
                />
              ) : (
                <div className="q-capture-actions">
                  {captureError && (
                    <div className="survey-error">
                      <div className="survey-error-message">
                        <i className="fa fa-exclamation-triangle" />
                        {t('error.errorNoCameraAccess')}
                      </div>
                    </div>
                  )}
                  <CaptureActionButton
                    className="q-capture-action"
                    onClick={e => e.preventDefault() & setCaptureOpen(true)}
                    {...themeColors}>
                    <i className="fa fa-video" />
                    {t('capture.videoButton')}
                  </CaptureActionButton>
                  <div className="q-video-field-video-upload">
                    <span className="q-video-field-video-upload-button">
                      <span>
                        <i className="fa fa-upload" /> {t('comment.video.uploadButton')}
                      </span>
                      <input
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={e => onFileChange({ value: e.target.files, ...defaultData })}
                      />
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="q-capture-actions">
              <div className="q-video-field-video-upload">
                <span className="q-video-field-video-upload-button">
                  <span>
                    <i className={`fa fa-${isMobile ? 'video' : 'upload'}`} />
                    {isMobile ? t('capture.videoButton') : t('comment.video.uploadButton')}
                  </span>
                  <input
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    capture="user"
                    onChange={e => onFileChange({ value: e.target.files, ...defaultData })}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="q-video-field-action">
          <VideoInfo canRecord={canRecord} isMobile={isMobile} captureOpen={captureOpen} video={video} t={t} />
          {video ? (
            <div className="q-video-field-action-buttons">
              <button
                className="q-video-field-button q-video-field-button--remove"
                onClick={e => e.preventDefault() & onChange({ name: videoName, value: undefined })}>
                <i className="fa fa-trash" />
                {t('comment.video.removeButton')}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
