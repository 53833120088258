import styled from '@emotion/styled'
import React from 'react'
import { getThemeVar } from '../theme'

export default function ProgressBar(props) {
  let { surveyInfo: { theme = {}, stepCount = 0 } = {}, stepNum = 0, position = 'after' } = props

  let { progressBarPosition = 'after' } = theme

  if (!theme.progressBar || progressBarPosition !== position || !stepCount) return null

  const showNumbers = !!theme.progressBarNumbers
  const primary = getThemeVar('primaryColor', theme.primaryColor)

  const ProgressBarWrapper = styled.div`
    padding: 10px;
  `
  const ProgressBar = styled.div`
    display: block;
    max-width: 360px;
    margin: 0 auto;
    padding: 7px;
    border-radius: 9px;
  `

  const ProgressBarBackground = styled.div`
    position: relative;
    height: 6px;
    background: #dddddd;
    border-radius: 3px;
  `

  const ProgressBarActive = styled.div`
    width: ${props => props.width}%;
    height: 6px;
    background: ${primary};
    border-radius: 3px;
  `

  const ProgressBarNumbers = styled.div`
    padding-top: 10px;
    text-align: center;
    font-weight: 700;
    color: #828282;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
  `

  const width = (stepNum / (stepCount + 1)) * 100

  return (
    <ProgressBarWrapper className="s-progressbar">
      <ProgressBar>
        <ProgressBarBackground>
          <ProgressBarActive width={width} />
        </ProgressBarBackground>
        {showNumbers && <ProgressBarNumbers>{`${stepNum}/${stepCount}`}</ProgressBarNumbers>}
      </ProgressBar>
    </ProgressBarWrapper>
  )
}
