import { List } from 'immutable'
import React from 'react'
import './file.sass'

const formatBytes = (a, b) => {
  if (0 == a) return '0 B'

  let c = 1024,
    d = b || 2,
    e = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c))

  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}

export default function FileField(props) {
  let { onFileChange, onFileRemove, name, value, t, multiple = false, fileType = '*', maxSize = 10485760 } = props

  let defaultData = { name, fileType, multiple, maxSize }

  // Add missing data for prefilled URL
  if (typeof value === 'string' && value.length > 0)
    value = List([{ preview: value, name: t('file.default.filename'), size: null }])

  /* If value is not set, then show upload button */
  if (!List.isList(value) || !value.size) {
    return (
      <div className="q-file">
        <div className="q-file-upload">
          <div className="q-file-upload-text">
            <i className="fa fa-file" />
            {t('file.upload')}
            <span className="q-file-upload-info">
              {` (${fileType !== '*' ? fileType.split(',').join('/') + ', ' : ''}max. ${formatBytes(maxSize)})`}
            </span>
          </div>
          <input
            type="file"
            accept={fileType}
            onChange={e => onFileChange({ value: e.target.files, ...defaultData })}
            capture="user"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="q-file q-file--selected">
      {value
        .map(({ preview, name: fileName, size }, fileKey) => {
          return (
            <div className="q-file-item" key={fileKey}>
              <div className="q-file-item-preview">
                <img src={preview} alt={fileName} />
              </div>
              <div className="q-file-item-name">
                <span className="ellipsis">
                  {fileName} {size ? <span className="q-file-item-name-size">{formatBytes(size)}</span> : null}
                </span>
              </div>
              <div className="q-file-item-remove">
                <button onClick={e => e.preventDefault() & onFileRemove({ name, fileKey })}>
                  <i className="fa fa-trash" />
                </button>
              </div>
            </div>
          )
        })
        .toArray()}
    </div>
  )
}
