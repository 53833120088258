import React, { useEffect } from 'react'
import ShareButtons from './share'
import './step.sass'
import './thanks.sass'

function FrameThanks({ title, text }) {
  return (
    <div className="frame-thanks">
      <h2>
        {title.split('\n').map((s, k) => (
          <div key={k}>{s}</div>
        ))}
      </h2>
      <div className="frame-thanks-content" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  )
}

export default function SurveyThanks(props) {
  let {
    surveyInfo = {},
    lang,
    view = 'default',
    t,
    surveyFeatures = [],
    handleSurveyReset,
    completeAnswer,
    preview = false,
    thanks: stepThanks,
  } = props

  useEffect(() => {
    if (surveyFeatures.includes('autoReset')) setTimeout(() => handleSurveyReset(), 5000)

    if (typeof completeAnswer === 'function') completeAnswer().catch(err => console.log(err))
  }, [])

  let { logo = false, company_name, title = false } = surveyInfo

  // let isCustom = !!stepThanks ||
  let thanks = stepThanks || surveyInfo.thanks || {}

  let { title: thanksTitle = {}, text: thanksText = {} } = thanks

  if (typeof thanksTitle[lang] === 'undefined') {
    thanksTitle = t('thanks.title')
  } else {
    thanksTitle = thanksTitle[lang]
  }

  if (typeof thanksText[lang] === 'undefined') {
    thanksText = /*html*/ `${t('thanks.text')}
    <div class="survey-thanks-greetings">${t('thanks.greetings')}<br>${company_name}</div>`
  } else {
    thanksText = thanksText[lang]
  }

  if (view === 'frame') return <FrameThanks title={thanksTitle} text={thanksText} />

  return (
    <div className="page-inner">
      <div className="survey-thanks">
        <div className="survey-thanks-inner">
          {!preview && (
            <div className="survey-thanks-header">
              {logo ? (
                <div className="survey-thanks-header-logo">
                  <img src={logo} />
                </div>
              ) : (
                <div className="survey-thanks-header-name">{title || company_name}</div>
              )}
            </div>
          )}
          <div className="survey-thanks-content">
            <div className="survey-thanks-content-message">
              <h2>
                {thanksTitle.split('\n').map((s, k) => (
                  <div key={k}>{s}</div>
                ))}
              </h2>
              <div className="survey-thanks-content-message-content" dangerouslySetInnerHTML={{ __html: thanksText }} />
            </div>
            <ShareButtons {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}
