import { List, Map } from 'immutable'
import React from 'react'
import './comment.sass'
import { ThemeTextarea } from './textarea'

function getPersonalInfo(values) {
  let info = []

  if (values.get('name')) info.push(values.get('name'))

  if (values.get('organization')) info.push(values.get('organization'))

  if (values.get('position')) info.push(values.get('position'))

  if (values.get('location')) info.push(values.get('location'))

  return info.join(' / ')
}

export default function Comment({ value, onChange, name, values = Map(), showPersonalInfo = false, themeVar, t }) {
  let image = null
  let imageValue = values.get('imageFilename')

  if (List.isList(imageValue) && imageValue.first()) {
    image = imageValue.first()?.preview
  } else if (typeof imageValue === 'string' && imageValue.length > 0) {
    image = imageValue
  }

  return (
    <div className="q-comment q-comment--text">
      <div className="q-comment-text">
        <div className="q-comment-text-input">
          <ThemeTextarea
            value={value || ''}
            rows="4"
            name={name}
            onChange={onChange}
            className="q-input q-textarea"
            primaryColor={themeVar('primaryColor')}
          />
          {showPersonalInfo ? (
            <div className="q-comment-text-input-user">
              {image ? (
                <div className="q-comment-text-input-user-image">
                  <img src={image} />
                </div>
              ) : (
                <div className="q-comment-text-input-user-icon" style={{ backgroundColor: themeVar('primaryColor') }}>
                  <i className="fa fa-user" />
                </div>
              )}
              <div className="q-comment-text-input-user-info">{getPersonalInfo(values)}</div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
