import styled from '@emotion/styled'
import React from 'react'

const ThemeInput = styled.input`
  display: block;
  width: 100%;
  line-height: 26px;
  border: 2px solid #cecece;
  padding: 6px 10px;
  border-radius: 2px;
  color: #222936;
  &:hover {
    border-color: #9b9b9b;
  }
  &:focus {
    outline: none;
    border-color: ${props => props.primaryColor};
  }
`

export default function InputField(props) {
  let { value = '', type, name, onChange, onBlur, placeholder = '', disabled = false, themeVar } = props

  return (
    <ThemeInput
      type={type}
      value={value}
      name={name}
      id={name}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      disabled={disabled}
      className="q-input"
      primaryColor={themeVar('primaryColor')}
    />
  )
}
