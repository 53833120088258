export const themeVariables = {
  style: 'color',
  pageBackgroundColor: '#f4f4f4',
  pageBackgroundColorType: 'light',
  pageBackgroundColorDark: 'hsl(0, 0%, 81%)',
  pageBackgroundColorLight: 'hsl(0, 0%, 96%)',
  pageBackgroundColorAlpha: 'hsla(0, 0%, 96%, 0.5)',
  primaryColor: '#0d3b70',
  primaryColorType: 'dark',
  primaryColorDark: 'hsl(212, 79%, 10%)',
  primaryColorLight: 'hsl(212, 79%, 25%)',
  primaryColorAlpha: 'hsla(212, 79%, 25%, 0.5)',
  highlightColor: '#40c9b2',
  highlightColorType: 'light',
  highlightColorDark: 'hsl(170, 56%, 37%)',
  highlightColorLight: 'hsl(170, 56%, 52%)',
  highlightColorAlpha: 'hsla(170, 56%, 52%, 0.5)',
  fontSource: 'system',
  fontFamily: 'inherit',
}

export function getThemeVar(key, overrideValue) {
  if (typeof overrideValue === 'object') overrideValue = overrideValue[key]

  return overrideValue || themeVariables[key] || ''
}

export function processFont(theme) {
  try {
    let font = theme && theme.font ? theme.font : themeVariables.font
    let [source, family = false] = font.split(':')

    return {
      source,
      family,
    }
  } catch (err) {
    return {
      source: 'system',
      family: false,
    }
  }
}

export function loadGoogleFont(family) {
  try {
    let link = document.getElementById('googe-font')
    if (!link) {
      link = document.createElement('link')
      link.rel = 'stylesheet'
      document.head.appendChild(link)
    }

    link.href = `https://fonts.googleapis.com/css2?family=${family.split(' ').join('+')}:wght@400;700&display=swap`
  } catch (err) {
    console.log(err)
  }
}
