import { fromJS, List, Map } from 'immutable'
import React, { Component, createContext } from 'react'
import Api from '../api'
import Footer from '../footer'
import { processLanguages } from '../i18n'
import session from '../session'
import { getThemeVar, loadGoogleFont, processFont } from '../theme'
import SurveyClosed from './closed'
import { resolveConditions } from './condition'
import SurveyLoading from './loader'
import SurveyNotFound from './notFound'
import Survey from './survey'
import SurveyThanks from './thanks'

const DEV = window.DEV || false

/* Store for survey */
export const SurveyContext = createContext({})
export const SurveyStore = SurveyContext.Consumer

export default class SurveyProvider extends Component {
  constructor(props) {
    super(props)

    let parts = window.location.pathname.split('/')
    let surveyId = false
    if (parts.length === 3) surveyId = parts[2]
    if (parts.length === 2) surveyId = parts[1]

    /* Check list person id */
    let listEventId = props.locationQuery.e || false
    let invitationEventId = props.locationQuery.i || false

    // Take all p[int] parameters
    let regex = '^[p][0-9]+$'
    let customParams = Object.keys(props.locationQuery).reduce((params, key) => {
      if (key.match(regex)) params[key] = decodeURIComponent(props.locationQuery[key])

      return params
    }, {})

    this.state = {
      answerId: false,
      surveyId,
      listEventId,
      invitationEventId,
      eventId: undefined,
      customParams,
      surveyLoading: surveyId ? true : false,
      surveyNotFound: surveyId ? false : true,
      surveyError: false,
      surveyClosed: false,
      surveySteps: [],
      surveyFeatures: [],
      surveyLangs: [],
      surveyTitle: {},
      surveyFont: {},
      activeStepKey: false,
      surveyInfo: {},
      surveyValues: Map(),
      surveySubmitting: false,
      lang: 'en',
      translations: {},
    }
  }

  componentDidMount() {
    let { locationQuery = {} } = this.props
    let { surveyId, listEventId, invitationEventId, customParams, surveyValues, activeStepKey, answerId } = this.state

    let values = {
      ...customParams,
      e: listEventId,
      i: invitationEventId,
      a: session.get(surveyId),
    }

    Api.get(`/collect/survey/${surveyId}`, values)
      .then(data => {
        let stateUpdate = { surveyLoading: false }

        if (data.success) {
          // Process languages and translations from api
          let { lang, translations, langs } = processLanguages(data.languages, locationQuery.lang)

          stateUpdate.lang = lang
          stateUpdate.translations = translations
          stateUpdate.surveyLangs = langs

          stateUpdate.surveyValues = data.surveyValues ? fromJS(data.surveyValues) : surveyValues
          stateUpdate.surveyInfo = data.info
          stateUpdate.surveySteps = data.steps
          stateUpdate.surveyFeatures = data.features
          stateUpdate.surveyTitle = data.title
          stateUpdate.surveyFont = processFont(data.info.theme)
          stateUpdate.activeStepKey = this.getNextStepKey(data.steps, activeStepKey, surveyValues, data.features)
          stateUpdate.activeStepInitialValues = this.getStepInitialValues(stateUpdate, lang)
          stateUpdate.eventId = data.eventId
          stateUpdate.answerId = data.answerId || answerId
        } else {
          stateUpdate.surveyNotFound = data.closed !== true
          stateUpdate.surveyClosed = data.closed === true
        }

        // Set page background
        if (this.props.view === 'default' && data.info) this.setPageStyles(data.info)

        this.setState(stateUpdate, () => this.setHtmlTitle())
      })
      .catch(err => {
        console.log(err)
        this.setState({
          surveyLoading: false,
          surveyNotFound: true,
        })
      })
  }

  componentDidUpdate() {
    setTimeout(() => this.props.onResize())
  }

  setPageStyles = ({ theme }) => {
    document.body.style.backgroundColor = getThemeVar('pageBackgroundColor', theme.pageBackgroundColor)
    if (theme.fontFamily)
      document.body.style.fontFamily = `'${theme.fontFamily}', -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif`

    if (theme.fontSource === 'google') loadGoogleFont(theme.fontFamily)
  }

  setHtmlTitle = () => {
    try {
      let t = this.translate
      let { surveyTitle, lang } = this.state

      let [title] = document.getElementsByTagName('title')
      title.innerHTML = surveyTitle[lang] || t('survey.title')
    } catch (err) {
      console.log(err)
    }
  }

  translate = (key, defaultValue, replacements = []) => {
    let { lang, translations } = this.state
    let langValues = translations[lang] || {}
    let value = langValues[key] || defaultValue || key

    if (Array.isArray(replacements) && replacements.length)
      value = replacements.reduce(
        (value, replacement, key) => value.replace(new RegExp(`:${key}`, 'g'), replacement),
        value
      )

    return value
  }

  setLanguage = lang => {
    let { surveyLangs = [] } = this.state
    let surveyLang = surveyLangs.find(i => i.lang === lang)

    if (!surveyLang) return console.log(`Language ${lang} not supported`)

    session.set('langCode', surveyLang.code)

    this.setHtmlLang(surveyLang.code)
    this.setState({ lang }, () => this.setHtmlTitle())
  }

  setHtmlLang = htmlLang => {
    try {
      let [html] = document.getElementsByTagName('html')
      html.lang = htmlLang
    } catch (err) {
      console.log(err)
    }
  }

  getStepInitialValues = ({ surveySteps = [], activeStepKey, surveyValues }, useLang) => {
    let lang = useLang || this.props.lang
    let { fields = [] } = surveySteps[activeStepKey] || {}

    fields = fields.reduce((acc, value) => {
      if (value.type === 'fieldset') return acc.concat(value.fields || [])

      return acc.concat(value)
    }, [])

    return fields.reduce((values, { name, system = false, defaultValue, sourceField }) => {
      let path = system ? [name] : ['custom', name]
      let surveyValue = surveyValues.getIn(path)
      if (typeof surveyValue !== 'undefined') {
        defaultValue = surveyValue
      } else if (typeof sourceField === 'string') {
        let sourceValue = surveyValues.getIn(sourceField.split(' '))
        if (typeof sourceValue !== 'undefined') defaultValue = sourceValue
      }

      if (defaultValue && typeof defaultValue === 'object' && typeof defaultValue[lang] === 'string') {
        return values.setIn(path, defaultValue[lang])
      } else if (typeof defaultValue !== 'undefined') {
        return values.setIn(path, defaultValue)
      }

      return values
    }, Map())
  }

  getNextStepKey = (steps, activeStepKey, values, features) => {
    if (!steps.length) return -1

    // If activeStepKey is false, we are in the beginning
    if (activeStepKey === false) return 0

    // If we have previous stepkey set, get next one where conditions are true
    for (let i = activeStepKey + 1; i < steps.length; i++) {
      let step = steps[i]
      if (!step) return -1

      let { conditions = [] } = step
      if (resolveConditions(conditions, values, features)) return i
    }

    // If no other step can be found, return -1  which is final thank you page
    return -1
  }

  handleFileUploads = (stepValues, fileFields) => {
    return (
      Promise.all(
        fileFields.map(field => {
          let value = stepValues.getIn(field.split('.'), false)

          if (!value) return false

          // For URL string leave filename only
          if (typeof value === 'string') return [value.split('/').pop()]

          return Promise.all(Array.from(value).map(f => this.uploadFile(f, field)))
        })
      )
        /*
         * Promise all uploads will return array of field values.
         * Here we go through all file fields and update fields value in stepValues
         * to uploaded file fields value, which is url to the file in S3.
         */
        .then(uploadedFileFields =>
          fileFields.reduce((stepValues, field, key) => {
            let value = uploadedFileFields[key] || []
            if (!Array.isArray(value) || !value.length) {
              value = ''
            } else if (value.length === 1) {
              value = value[0]
            }
            return stepValues.setIn(field.split('.'), value)
          }, stepValues)
        )
    )
  }

  handleCommentUploads = (stepValues, commentFields) => {
    return Promise.all(
      commentFields.map(field => {
        let videoName = `${field}_video`
        let videos = stepValues.getIn(videoName.split('.'), false)

        if (!List.isList(videos))
          return {
            videoName,
            fileList: [videos],
          }

        return Promise.all(Array.from(videos).map(f => this.uploadFile(f, field))).then(fileList => ({
          videoName,
          fileList,
        }))
      })
    ).then(uploadedFileFields =>
      commentFields.reduce((stepValues, field, key) => {
        let fieldValue = uploadedFileFields[key] || false

        if (!fieldValue) return stepValues

        let { videoName, fileList } = fieldValue
        let fileName = fileList[0] || false

        return stepValues.setIn(videoName.split('.'), fileName)
      }, stepValues)
    )
  }

  uploadFile = (file, field) => {
    return Api.post(`/collect/file`, { filename: file.name, type: file.type, size: file.size })
      .then(data => data.data || {})
      .then(({ postData, filename }) => {
        let formData = new FormData()
        for (let key in postData.fields) {
          formData.append(key, postData.fields[key])
        }

        formData.append('file', file, filename)

        return Api.uploadFile(postData.url, formData).then(res => filename)
      })
      .catch(err => {
        // Throw errors
        throw {
          errors: {
            [field]: [this.translate(err.status === 422 ? 'file.errorMaxSize' : 'error')],
          },
        }
      })
  }

  mergeStepValues = (surveyValues, stepValues) => {
    let surveyCustom = surveyValues.get('custom', Map())
    let { custom = {}, ...values } = stepValues
    return surveyValues.merge(fromJS(values)).set('custom', surveyCustom.merge(fromJS(custom)))
  }

  handleStepSubmit = (stepValues, fileFields = [], commentFields = []) => {
    let {
      surveyValues,
      surveyId,
      listEventId,
      invitationEventId,
      customParams,
      surveySteps,
      activeStepKey,
      surveyFeatures,
      answerId,
      eventId,
    } = this.state

    let { lang } = this.props

    let { id: stepId } = surveySteps[activeStepKey] || {}

    this.setState({
      surveySubmitting: true,
    })

    let queryParams = { ...customParams, e: listEventId, i: invitationEventId }

    return this.handleFileUploads(stepValues, fileFields)
      .then(stepValues => this.handleCommentUploads(stepValues, commentFields))
      .then(stepValues =>
        Api.post(`/collect/survey/${surveyId}`, { stepValues, stepId, answerId, eventId, lang }, queryParams).then(
          data => {
            let stateUpdate = { surveySubmitting: false }

            if (!data.success) throw 'data'

            stateUpdate.surveyValues = this.mergeStepValues(surveyValues, data.stepValues)
            stateUpdate.answerId = data.answerId

            // Only save answer id to localStorage if no invitation id found
            if (!invitationEventId) session.set(surveyId, data.answerId)

            if (listEventId) stateUpdate.listEventId = false

            if (invitationEventId) stateUpdate.invitationEventId = false

            if (customParams) stateUpdate.customParams = false

            stateUpdate.activeStepKey = this.getNextStepKey(
              surveySteps,
              activeStepKey,
              stateUpdate.surveyValues,
              surveyFeatures
            )
            stateUpdate.activeStepInitialValues = this.getStepInitialValues({ ...this.state, ...stateUpdate })

            return this.setState(stateUpdate, () => window.scrollTo(0, 0))
          }
        )
      )
      .catch(err => {
        this.setState({
          surveyError: !err.errors,
          surveySubmitting: false,
        })

        let thr = {}
        if (err.errors) {
          thr.errors = err.errors
        } else {
          thr.message = this.translate('error')
        }

        throw thr
      })
  }

  handleSurveyReset = () => {
    let { surveyFeatures, surveySteps } = this.state

    let stateUpdate = {
      surveyValues: Map(),
      answerId: false,
    }

    stateUpdate.activeStepKey = this.getNextStepKey(surveySteps, false, stateUpdate.surveyValues, surveyFeatures)
    stateUpdate.activeStepInitialValues = this.getStepInitialValues({ ...this.state, ...stateUpdate })

    this.setState(stateUpdate)
  }

  trackEvent = (event, params = {}) => {
    let { surveyId, answerId } = this.state
    return Api.post('/collect/event', { surveyId, answerId, event, params })
  }

  completeAnswer = () => {
    let { answerId } = this.state
    return Api.post(`/collect/complete/${answerId}`)
  }

  render() {
    let { children, ...restProps } = this.props
    const t = this.translate

    let {
      surveyId,
      customParams,
      surveyLoading,
      surveyInfo,
      surveyNotFound,
      surveyClosed,
      activeStepKey,
      activeStepInitialValues,
      surveySteps,
      surveySubmitting,
      surveyFeatures,
      surveyTitle,
      surveyLangs,
      surveyValues,
      surveyError,
      answerId,
      lang,
    } = this.state

    let { theme = {} } = surveyInfo

    let activeStep = surveySteps[activeStepKey] || 0

    let contextValue = {
      surveyId,
      customParams,
      surveyInfo,
      activeStepKey,
      activeStep,
      surveySubmitting,
      surveyFeatures,
      surveyTitle,
      surveyLangs,
      surveyValues,
      surveyError,
      handleStepSubmit: this.handleStepSubmit,
      handleSurveyReset: this.handleSurveyReset,
      activeStepInitialValues,
      trackEvent: this.trackEvent,
      completeAnswer: this.completeAnswer,
      answerId,
      lang,
      setLanguage: this.setLanguage,
      theme,
      t,
      ...restProps,
    }

    return (
      <SurveyContext.Provider value={contextValue}>
        {surveyClosed ? (
          <SurveyClosed {...contextValue} />
        ) : surveyNotFound ? (
          <SurveyNotFound {...contextValue} />
        ) : surveyLoading ? (
          <SurveyLoading {...contextValue} />
        ) : !activeStep ? (
          <SurveyThanks {...contextValue} />
        ) : (
          <Survey {...contextValue} />
        )}
        <Footer {...contextValue} />
      </SurveyContext.Provider>
    )
  }
}
