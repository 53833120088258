import React from 'react'
import Select from 'react-select'

export function dropdownStyles(primaryColor) {
  return {
    option: (provided, state) => {
      let backgroundColor = '#fff'
      if (state.isSelected) backgroundColor = primaryColor

      return {
        ...provided,
        backgroundColor: backgroundColor,
        ':hover': {
          backgroundColor: state.isSelected ? primaryColor : '#f4f4f4',
        },
      }
    },
    control: (provided, state) => {
      return {
        ...provided,
        border: `2px solid ${state.isFocused ? primaryColor : '#cecece'}`,
        boxShadow: `0 0 1px ${state.isFocused ? primaryColor : '#cecece'}`,
        borderRadius: '2px',
        color: '#222936',
        ':hover': {
          borderColor: state.isFocused ? primaryColor : '#b3b3b3',
        },
      }
    },
    indicatorsContainer: (provided, state) => {
      return {
        ...provided,
        padding: '1px',
      }
    },
  }
}

export default function Dropdown(props) {
  let { name, options = [], value, onChange, t, lang, validation, themeVar } = props
  let isRequired = validation.includes('required')

  options = options.map(current => {
    return { value: current.value, label: current.label[lang] || '' }
  })

  if (!isRequired)
    options.unshift({
      value: '',
      label: t('dropdown.selectValue'),
    })

  function selectOnChange(event) {
    onChange({
      name: name,
      value: event.value,
    })
  }

  return (
    <Select
      styles={dropdownStyles(themeVar('primaryColor'))}
      options={options}
      onChange={selectOnChange}
      defaultValue={options.find(o => o.value === value)}
      placeholder={t('dropdown.selectValue')}
      key={lang}
    />
  )
}
