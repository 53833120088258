import React from 'react'
import './stars.sass'

export default function Stars(props) {
  let { value = '', name, onChange, t, rangeMinLabel = {}, rangeMaxLabel = {}, lang } = props

  let minLabel = rangeMinLabel[lang] || ''
  let maxLabel = rangeMaxLabel[lang] || ''

  let steps = [1, 2, 3, 4, 5]

  if (typeof value === 'string') value = parseInt(value)

  let hasValue = typeof value === 'number'

  let [hoverValue, setHoverValue] = React.useState(undefined)
  if (!hasValue) {
    value = undefined
  }

  return (
    <div className="q-stars-area">
      <div className="q-stars-wrapper">
        <div className="q-stars">
          {steps.map((step, index) => {
            const classes = ['q-stars-item']
            const displayValue = hoverValue !== undefined ? hoverValue : value
            if (displayValue !== undefined) {
              if (step === displayValue) {
                classes.push('q-stars-item--selected')
              } else if (step <= displayValue) {
                classes.push('q-stars-item--under')
              } else {
                classes.push('q-stars-item--over')
              }
            }
            return (
              <button
                key={step}
                className={classes.join(' ')}
                onMouseEnter={e => setHoverValue(step)}
                onMouseLeave={e => setHoverValue(undefined)}
                onClick={e => e.preventDefault() & onChange({ name, value: step })}>
                <i className="fa fa-star" />
              </button>
            )
          })}
        </div>
        <div className="q-stars-labels-text">
          <div className="q-stars-labels-text-min">{minLabel}</div>
          <div className="q-stars-labels-text-max">{maxLabel}</div>
        </div>
      </div>
    </div>
  )
}
