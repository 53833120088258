import React from 'react'
import { ThemeSelectIcon } from './checkbox'
import './checkbox.sass'

export default function Radios({ name, options = [], value, onChange, lang, themeVar }) {
  return (
    <div className="q-input q-input--radios">
      {options.map(({ value: optionValue, label = {} }) => {
        let classes = ['q-radio']
        let isChecked = optionValue === value

        if (isChecked) classes.push('q-radio--selected')

        label = label[lang] || ''

        return (
          <button
            className={classes.join(' ')}
            onClick={e => e.preventDefault() & onChange({ name, value: optionValue })}
            key={optionValue}>
            <span className="q-radio-icon-wrapper">
              <ThemeSelectIcon className="q-radio-icon" color={themeVar('primaryColor')}>
                {isChecked ? <i className="fa fa-check" /> : null}
              </ThemeSelectIcon>
            </span>
            <span className="q-radio-label">{label}</span>
          </button>
        )
      })}
    </div>
  )
}
