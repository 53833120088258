import { fromJS, List } from 'immutable'
import React from 'react'
import Select from 'react-select'
import { dropdownStyles } from './dropdown'

export default function MultiDropdown(props) {
  let { name, options = [], value, onChange, t, lang, themeVar } = props

  if (!List.isList(value)) value = List()

  options = options.map(current => {
    return { value: current.value, label: current.label[lang] || '' }
  })

  function selectOnChange(event) {
    onChange({
      name: name,
      value: fromJS(event.map(o => o.value)),
    })
  }

  return (
    <Select
      styles={dropdownStyles(themeVar('primaryColor'))}
      isMulti={true}
      options={options}
      onChange={selectOnChange}
      defaultValue={options.filter(o => value.includes(o.value))}
      placeholder={t('dropdown.selectValue')}
      key={lang}
    />
  )
}
