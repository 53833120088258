import session from './session'

// Update 03/2021
// Back end returns active translations and languages
// Here we process those
export function processLanguages(langs, queryLang) {
  let lang = false

  // se -> sv legacy fix
  queryLang = queryLang === 'se' ? 'sv' : queryLang
  // Try to find query lang (?lang=fi) and use it
  queryLang = langs.find(i => i.code === queryLang)
  if (queryLang) lang = queryLang.lang

  // If localStorage lang is found, use it
  let localLang = session.get('langCode')
  localLang = langs.find(i => i.code === localLang)
  if (localLang) lang = localLang.lang

  // If lang is not set in url, try to get it from browser
  if (!lang && navigator.language) {
    let [browserLang] = navigator.language.split('-')
    browserLang = langs.find(i => i.code === browserLang)
    if (browserLang) lang = browserLang.lang
  }

  // If lang is not found from url or browser, use main lang
  let mainLang = langs.find(i => !!i.main)
  if (!lang && mainLang) lang = mainLang.lang

  let translations = langs.reduce((items, { lang, translations }) => {
    items[lang] = translations
    return items
  }, {})

  return {
    lang,
    translations,
    langs,
  }
}
