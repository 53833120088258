function request(method, url, data = {}, isFormData = false) {
  let options = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  }

  if (isFormData) {
    options.body = data
    delete options.headers
  } else if (!['GET', 'HEAD'].includes(method)) {
    options.body = JSON.stringify(data)
  }

  return fetch(url, options)
    .then(res => {
      if (res.status > 299) throw res

      return isFormData ? res : res.json()
    })
    .catch(err =>
      Promise.resolve(err.json ? err.json() : err).then(data => {
        data._apierror = true
        data.status = err.status
        throw data
      })
    )
}

let processQuery = (path, query, defaultQuery = {}) => {
  query = Object.assign({}, defaultQuery, query)
  if (Object.keys(query).length) {
    let queryParts = []

    for (let key in query) {
      let value = query[key]
      queryParts.push(key + '=' + encodeURIComponent(value))
    }

    path = `${path}?${queryParts.join('&')}`
  }
  return `${API_DOMAIN}${path}`
}

const Api = {
  get(path, query = {}) {
    return request('GET', processQuery(path, query))
  },
  post(path, data, query = {}) {
    return request('POST', processQuery(path, query), data)
  },
  patch(path, data, query = {}) {
    return request('PATCH', processQuery(path, query), data)
  },
  delete(path, data, query = {}) {
    return request('DELETE', processQuery(path, query), data)
  },
  uploadFile(url, formData) {
    return request('POST', url, formData, true)
  },
}

export default Api
