import React from 'react'

const typeIcons = {
  success: 'fa-check-circle',
  info: 'fa-info-circle',
  warning: 'fa-warning',
  error: 'fa-exclamation-circle ',
}

export default function MessagesElement({ messages = [] }) {
  if (!messages.length) return null

  return (
    <div className="messages">
      {messages.map((message, key) => {
        if (!message) return

        if (typeof message === 'text') message = { text: message, type: 'error' }

        let { type = 'error', text } = message

        let iconClasses = ['fa', typeIcons[type] || '']
        let classes = ['message', `message--${type}`]

        return (
          <div className={classes.join(' ')} type={type} key={key}>
            <i className={iconClasses.join(' ')} />
            <span className="message-text">{text}</span>
          </div>
        )
      })}
    </div>
  )
}
