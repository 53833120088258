import React from 'react'

const SHARE_DOMAIN = window.SHARE_DOMAIN || 'https://www.trustmary.com'

export default function ShareButtons(props) {
  let { surveyValues, surveyFeatures, surveyInfo, t, trackEvent, answerId } = props

  let { public_profile: publicProfile } = surveyInfo

  if (!publicProfile) return null

  if (!surveyFeatures.includes('socialMediaShare')) return null

  if (surveyValues.get('publicConsent') !== 'true') return null

  let {
    twitter = false,
    twitterHandle = false,
    linkedin = false,
    facebook = false,
    googleReview = false,
    googleReviewUrl = false,
  } = surveyInfo.share || {}

  googleReview = googleReview && typeof googleReviewUrl === 'string' && googleReviewUrl.length

  if (!linkedin && !twitter && !facebook && !googleReview) return null

  let comment = surveyValues.get('comment', '')
  let commentVideo = surveyValues.get('commentVideo', '')

  let shareUrl = encodeURIComponent(`${SHARE_DOMAIN}/${t('publicProfile.prefix')}/${publicProfile}/${answerId}`)

  if (comment.trim() === '' && !commentVideo) return null

  return (
    <div className="survey-share">
      <div className="survey-share-title">{t('share.title')}</div>
      <div className="survey-share-buttons">
        {twitter ? (
          <a
            href={`https://twitter.com/intent/tweet?text=${shareUrl}${
              twitterHandle ? encodeURIComponent(' ' + twitterHandle) : ''
            }`}
            className="survey-share-button survey-share-button--twitter"
            target="_blank"
            onClick={e => trackEvent('share', { service: 'twitter' })}>
            <i className="fab fa-twitter" />
            {t('share.twitter')}
          </a>
        ) : null}
        {linkedin ? (
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=&summary=${comment}&source=`}
            className="survey-share-button survey-share-button--linkedin"
            target="_blank"
            onClick={e => trackEvent('share', { service: 'linkedin' })}>
            <i className="fab fa-linkedin" />
            {t('share.linkedin')}
          </a>
        ) : null}
        {facebook ? (
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
            className="survey-share-button survey-share-button--facebook"
            target="_blank"
            onClick={e => trackEvent('share', { service: 'facebook' })}>
            <i className="fab fa-facebook" />
            {t('share.facebook')}
          </a>
        ) : null}
        {googleReview ? (
          <a
            href={googleReviewUrl}
            className="survey-share-button survey-share-button--google"
            target="_blank"
            onClick={e => trackEvent('share', { service: 'googleReview' })}>
            <i className="fab fa-google" />
            {t('share.googleReview')}
          </a>
        ) : null}
      </div>
    </div>
  )
}
