import { fromJS, List, Map } from 'immutable'
import React, { Component, createContext, useContext } from 'react'
import Messages from './messages'
import Validator from './validator'

export const FormContext = createContext({})
export const FormStore = FormContext.Consumer

function getExtension(name) {
  if (typeof name !== 'string') return null

  let nameParts = name.split('.')
  let extension = nameParts[nameParts.length - 1]
  return extension.toLowerCase()
}

export default class FormComponent extends Component {
  state = {
    formState: {
      values: Map(),
      changes: Map(),
      errors: {},
      errorsMap: Map(),
      messages: [],
    },
    formLoading: false,
  }

  constructor(props) {
    super(props)

    let { values } = props

    if (!Map.isMap(values)) values = fromJS(values)

    if (!Map.isMap(values)) return

    let { formState } = this.state
    formState.values = values
    formState.errors = {}
    formState.messages = []
    this.state.formState = formState
  }

  handleFormInit = values => {
    if (!Map.isMap(values)) values = fromJS(values)

    let { formState } = this.state
    formState.values = values
    formState.errors = {}
    formState.messages = []
    this.setState({ formState })
  }

  handleFormUpdate = e => {
    let target = e.target || e
    let { formState } = this.state
    let { values, changes } = formState
    let { name, value } = target

    values = values.setIn(name.split('.'), value)
    changes = changes.setIn(name.split('.'), value)

    formState.values = values
    formState.changes = changes
    this.setState({
      formState,
    })
  }

  handleFormSubmit = e => {
    if (e && e.preventDefault) e.preventDefault()

    let { validation: propValidation = false, onSubmit } = this.props

    let stateUpdate = {}
    let { formState } = this.state
    let { values, changes } = formState
    let errors = {}
    let errorsMap = Map()
    let validation = false

    // Check the type of given validation and try to get it
    if (typeof propValidation === 'function') {
      validation = propValidation(this.props, values, changes)
    } else if (typeof propValidation === 'object') {
      validation = propValidation
    }

    // If validation is false, then form is automatically valid
    errors = validation ? Validator(values, validation, this.props.t) : {}

    // If there are no errors, run submit handler
    if (!Object.keys(errors).length) {
      let submitReturn = onSubmit.call(this, values, changes, this.handleFormSubmitDone)

      // If submit handler returns a promise, call done at then
      if (submitReturn && typeof submitReturn.then === 'function')
        submitReturn.then(res => this.handleFormSubmitDone(res)).catch(err => this.handleFormSubmitError(err))

      stateUpdate.formLoading = true
    } else {
      for (let name in errors) {
        errorsMap = errorsMap.setIn(name.split('.'), errors[name])
      }
    }

    formState.errors = errors
    formState.errorsMap = errorsMap
    stateUpdate.formState = formState
    this.setState(stateUpdate)
  }

  handleFormSubmitDone = resState => {
    if (typeof resState !== 'object') resState = {}

    let state = {}
    state.formLoading = false

    // Update errors map
    if (resState.errors) {
      resState.errorsMap = Map()
      for (let name in resState.errors) {
        let value = resState.errors[name]
        resState.errorsMap = resState.errorsMap.setIn(name.split('.'), value)
      }
    }

    // Set messages
    if (resState.message) {
      resState.messages = resState.messages || []
      resState.messages.push({
        type: 'error',
        text: resState.message,
      })
    }

    if (Array.isArray(resState.messages))
      state.messages = resState.messages.map(message =>
        typeof message === 'string' ? { type: 'error', text: message } : message
      )

    state.formState = Object.assign({}, this.state.formState, resState)
    this.setState(state)
  }

  handleFormSubmitError = err => {
    let { formState } = this.state

    if (err.errors) {
      formState.errors = err.errors
      formState.errorsMap = Object.keys(err.errors).reduce(
        (map, name) => map.setIn(name.split('.'), err.errors[name]),
        Map()
      )
    }

    if (err.message) formState.messages = [{ type: 'error', text: err.message }]

    this.setState({
      formLoading: false,
      formState,
    })
  }

  handleSetLoading = loading => {
    this.setState({
      formLoading: typeof loading === 'boolean' ? loading : this.state.formLoading === false,
    })
  }

  handleFormFileUpdate = data => {
    let { name, value: files, multiple = false, fileType = '*', maxSize = 10485760 } = data

    let acceptedFiletypes = fileType === '*' ? [] : fileType.split(',').map(f => f.replace('.', ''))

    let { t } = this.props

    let { formState } = this.state
    let { values, errors } = formState

    if (!files) {
      delete errors[name]
      values = value.removeIn(name.split('.'))
    } else {
      let value = multiple ? values.getIn(name.split('.'), List()) : List()

      let fileErrors = []

      for (let i = 0; i < files.length; i++) {
        let file = files[i]
        let fileError = false
        let { size, type, name } = file
        let extension = getExtension(name)

        /* Don't add files that are already selected */
        if (value.find(f => f.name === name)) fileError = t('file.errorFileTaken')

        if (size > maxSize) {
          fileError = t('file.errorMaxSize')
        } else if (acceptedFiletypes.length && acceptedFiletypes.indexOf(extension) === -1) {
          fileError = t('file.errorWrongType')
        }

        if (fileError) {
          fileErrors.push(`${t('file.error', 'Error')}: ${name} ${fileError}`)
          continue
        }

        file.preview = URL.createObjectURL(file)

        value = value.push(file)
      }

      if (fileErrors.length) {
        errors[name] = fileErrors
      } else {
        delete errors[name]
      }

      values = values.setIn(name.split('.'), value)
    }

    formState.values = values
    formState.errors = errors
    this.setState({ formState })
  }

  handleFormFileRemove = data => {
    let { name, fileKey } = data

    let { formState } = this.state
    let { values } = formState

    let value = values.getIn(name.split('.'), List())

    if (typeof value === 'string') {
      // Change prefilled URL to empty List
      formState.values = values.setIn(name.split('.'), List())
    } else formState.values = values.setIn(name.split('.'), value.remove(fileKey))

    this.setState({ formState })
  }

  render() {
    let { id, children, t, lang, className: propClass } = this.props
    let { formState, formLoading } = this.state

    let className = ['form']
    if (formLoading) className.push('form--processing')

    if (propClass) className.push(propClass)

    let contextValue = {
      formState,
      formLoading,
      formUpdate: this.handleFormUpdate,
      formFileUpdate: this.handleFormFileUpdate,
      formFileRemove: this.handleFormFileRemove,
      formInit: this.handleFormInit,
      formSetLoading: this.handleSetLoading,
      formSubmit: this.handleFormSubmit,
      t,
      lang,
    }

    return (
      <FormContext.Provider value={contextValue}>
        <form id={id} onSubmit={this.handleFormSubmit} className={className.join(' ')}>
          <Messages messages={formState.messages || []} />
          {children}
        </form>
      </FormContext.Provider>
    )
  }
}

export function useForm() {
  return useContext(FormContext)
}
