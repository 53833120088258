import React from 'react'

export default function SurveyNotFound({ t }) {
  return (
    <div className="page-inner">
      <div className="survey-notfound">
        <div className="survey-notfound-inner">
          <i className="fa fa-exclamation-triangle" />
          <div className="survey-notfound-text">Survey not found</div>
        </div>
      </div>
    </div>
  )
}
