import styled from '@emotion/styled'
import React from 'react'
import './range.sass'

const RangeItemButton = styled.div`
  .q-range-item:hover & {
    border-color: ${props => props.main};
  }

  .q-range-item:focus & {
    box-shadow: 0 0 0 2px ${props => props.main};
    border-color: ${props => props.main};
  }

  .q-range-item--selected &,
  .q-range-item--selected:focus &,
  .q-range-item--selected:hover & {
    background: ${props => props.main};
    border-color: ${props => props.dark};
  }
`

const NO_OPINION = '_no'

export default function Range(props) {
  let {
    value = '',
    name,
    onChange,
    t,
    rangeMin,
    rangeMax,
    rangeMinLabel = {},
    rangeMaxLabel = {},
    defaultLabels,
    lang,
    rangeNoOpinion = false,
    rangeNoOpinionLabel = {},
    themeColors,
  } = props

  if (defaultLabels) {
    rangeMinLabel[lang] = t('nps.label0')
    rangeMaxLabel[lang] = t('nps.label10')
  }

  let minLabel = rangeMinLabel[lang] || ''
  let maxLabel = rangeMaxLabel[lang] || ''

  let steps = []
  for (let i = parseInt(rangeMax); i >= parseInt(rangeMin); i--) steps.push(i)

  let classes = ['range']
  let hasValue = typeof value === 'number' || value === NO_OPINION
  let numericValue = typeof value === 'number' ? value : -1

  if (hasValue) {
    classes.push('range--has-value')
  } else {
    value = undefined
  }

  rangeNoOpinionLabel = rangeNoOpinionLabel[lang] || t('range.noOpinion')

  return (
    <div className="q-range-area">
      <div className="q-range-wrapper">
        <div className="q-range">
          {steps.map((step, index) => {
            const classes = ['q-range-item']
            if (hasValue) {
              if (step === numericValue) {
                classes.push('q-range-item--selected')
              } else if (step <= numericValue) {
                classes.push('q-range-item--under')
              } else {
                classes.push('q-range-item--over')
              }
            }
            return (
              <button
                className={classes.join(' ')}
                key={step}
                onClick={e => e.preventDefault() & onChange({ name, value: value === step ? undefined : step })}>
                <RangeItemButton className="q-range-item-button" {...themeColors}>
                  <span className="q-range-label">{step}</span>
                  {index === steps.length - 1 ? <span className="q-range-mobile-label">{minLabel}</span> : null}
                  {index === 0 ? <span className="q-range-mobile-label">{maxLabel}</span> : null}
                </RangeItemButton>
              </button>
            )
          })}
        </div>
        <div className="q-range-labels-text">
          <div className="q-range-labels-text-max">{maxLabel}</div>
          <div className="q-range-labels-text-min">{minLabel}</div>
        </div>
      </div>
      {rangeNoOpinion && (
        <div className="q-range-no-opinion">
          <div className="q-range-no-opinion-item">
            <button
              className={`q-range-item q-range-item--icon ${value === NO_OPINION ? 'q-range-item--selected' : ''}`}
              onClick={e => e.preventDefault() & onChange({ name, value: NO_OPINION })}>
              <div className="q-range-item-button">
                <span className="q-range-label">
                  <div className="q-range-item-icon">{value === NO_OPINION && <i className="fa fa-check" />}</div>
                </span>
                <span className="q-range-mobile-label">{rangeNoOpinionLabel}</span>
              </div>
            </button>
          </div>
          <div className="q-range-no-opinion-label">
            <div className="q-range-no-opinion-label-text">{rangeNoOpinionLabel}</div>
          </div>
        </div>
      )}
    </div>
  )
}
