import React, { Component, Fragment } from 'react'
import { getThemeVar } from '../theme'

export default class LangSwitcher extends Component {
  state = {
    open: false,
  }

  changeLang = lang => {
    this.props.setLanguage(lang)
    this.setState({
      open: false,
    })
  }

  render() {
    let { open } = this.state

    let { surveyLangs, theme, lang } = this.props

    if (surveyLangs.length < 2) return null

    let backgroundType = getThemeVar('pageBackgroundColorType', theme.pageBackgroundColorType)

    let { name: langLabel = lang } = surveyLangs.find(i => i.lang == lang) || {}

    return (
      <div className={`survey-langswitcher survey-langswitcher--${backgroundType}`}>
        <div className="survey-langswitcher-select">
          <button onClick={e => e.preventDefault() & this.setState({ open: true })}>
            <i className="fa fa-globe" />
            {langLabel}
          </button>
          {open ? (
            <Fragment>
              <div
                className="survey-langswitcher-overlay"
                onClick={e => e.preventDefault() & this.setState({ open: false })}
              />
              <div className="survey-langswitcher-menu">
                {surveyLangs.map(({ lang, name }) => {
                  return (
                    <button onClick={e => e.preventDefault() & this.changeLang(lang)} key={lang}>
                      {name || lang}
                    </button>
                  )
                })}
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
    )
  }
}
