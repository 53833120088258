import React from 'react'

export default function SurveyLoaderWrapped() {
  return (
    <div className="page-inner">
      <div className="survey-loader">
        <i className="fa fa-spinner fa-spin" />
      </div>
    </div>
  )
}
