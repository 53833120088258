import { List } from 'immutable'

const isEmail = str => {
  if (typeof str !== 'string') return false

  let regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  return regex.test(str.toLowerCase())
}

export default function Validator(values, rules = {}, t) {
  if (typeof t !== 'function') t = key => key

  let errors = {}

  let setError = (name, error) => {
    errors[name] = errors[name] || []
    if (errors[name].indexOf(error) === -1) errors[name].push(error)
  }

  for (let name in rules) {
    let ruleSet = rules[name]
    let namePath = name.split('.')
    let value = values.getIn(namePath, '')
    for (let rule of ruleSet) {
      let [type, attr1 = '', attr2 = '', attr3 = '', ...attrs] = rule.split(':')

      if (type === 'required') {
        if (!value && value !== 0) setError(name, t('validation.required'))
        else if (List.isList(value) && value.size < 1) setError(name, t('validation.required'))
      }

      if (type === 'requiredIfNot') {
        if (!value && !values.getIn(attr1.split('.'))) setError(name, t('validation.requiredIfNot'))
      }

      if (type === 'not') {
        let items = attr1.split(',')
        if (items.length && items.indexOf(value) !== -1) setError(name, t('validation.taken'))
      }

      if (type === 'min') {
        if (value && value.length < attr1) setError(name, t('validation.min', '', [attr1]))
      }

      if (type === 'max') {
        if (value && value.length > attr1) setError(name, t('validation.max', '', [attr1]))
      }

      if (type === 'email') {
        if (value && !isEmail(value)) setError(name, t('validation.email'))
      }

      if (type === 'same') {
        if (value !== values.get(attr1)) setError(name, t('validation.same', '', [attr1]))
      }
    }
  }

  return errors
}
