import { List, Map } from 'immutable'
import React, { Component, useEffect } from 'react'
import { Form, FormStore, useForm } from '../form/index'
import { QuestionField } from '../question'
import SurveyAction from './action'
import { resolveConditions } from './condition'
import SurveyHeader from './header'
import LangSwitcher from './langSwitch'
import ProgressBar from './progressBar'
import SurveyThanks from './thanks'

function UrlValues() {
  let { formUpdate } = useForm()

  useEffect(() => {
    let { hash } = window.location

    if (hash && hash.startsWith('#')) hash = hash.substr(1)

    if (!hash || !hash.length) return

    try {
      let values = JSON.parse(decodeURIComponent(hash))
      for (let name in values) {
        let value = values[name]
        formUpdate({ name, value })
      }
    } catch (err) {}

    window.location.hash = ''
  }, [1])

  return null
}

/* Filter fields recursively if conditions aren't met with given values */
function processFields(fields, values = Map(), features = []) {
  return fields.reduce((fields, field) => {
    let { type, conditions = [], fields: fieldsetFields = [] } = field

    if (!resolveConditions(conditions, values, features)) return fields

    /**
     * Clone field to prevent overriding by reference
     */
    field = Object.assign({}, field)

    if (type === 'fieldset' && fieldsetFields.length) field.fields = processFields(fieldsetFields, values)

    return [...fields, field]
  }, [])
}

function generateValidator(fields) {
  let parseFromFields = (fields, validations = {}) =>
    fields.reduce((validations, { name, validation = [], type, fields = [], system = false }) => {
      if (!system && name) name = `custom.${name}`

      if (type === 'fieldset' && fields.length) validations = parseFromFields(fields, validations)

      if (type === 'textfield') validation.push('max:255')

      if (type === 'textarea' || type === 'comment') validation.push('max:10000')

      if (validation.length && name) validations[name] = validation

      // Force required for privacy policy
      if (type === 'privacy_policy') validations[name] = ['required']

      return validations
    }, validations)

  return (props, values) => parseFromFields(processFields(fields, values))
}

function renderFields(fields, lang, t, features, info, surveyValues, formContext) {
  return fields.map(field => {
    let {
      name,
      system = false,
      type,
      label = {},
      description = {},
      options,
      inputLabel,
      fields = [],
      fieldset = {},
      conditions = [],
      ...rest
    } = field

    let fieldName = system ? name : `custom.${name}`

    let props = {
      ...rest,
      key: fieldName,
      name: fieldName,
      type,
      options,
      inputLabel,
      label: label[lang],
      description: description[lang],
      conditions,
      features,
      info,
      surveyValues,
      renderFields,
      formContext,
      lang,
      t,
    }

    if (type === 'fieldset') {
      props.fields = renderFields(fields, lang, t, features, info, surveyValues, formContext)
      props.fieldset = fieldset // Fieldset contains configuration for fieldset
    }

    return React.createElement(QuestionField, props)
  })
}

function mergeValues(surveyValues, formValues) {
  let custom = surveyValues.get('custom', Map()).merge(formValues.get('custom', Map()))
  return surveyValues.merge(formValues).set('custom', custom)
}

/* First process fields and then render fields that are left */
function SurveyFields({ fields, lang, surveyValues, features, info, onResize, t }) {
  return (
    <FormStore>
      {formContext => {
        let {
          formState: { values },
        } = formContext
        let processedFields = processFields(fields, mergeValues(surveyValues, values))

        // useEffect runs always when processedFields.length changes
        useEffect(() => onResize(), [processedFields.length])

        return renderFields(processedFields, lang, t, features, info, surveyValues, formContext)
      }}
    </FormStore>
  )
}

function preprocessValuesForSubmit(fields, values, data) {
  data = data || {
    stepValues: Map(),
    fileFields: List(),
    commentFields: List(),
  }

  return fields.reduce((data, { name, type, system, defaultValue = '', fields: fieldsetFields = [] }) => {
    if (type === 'fieldset') {
      data = preprocessValuesForSubmit(fieldsetFields, values, data)
    } else {
      name = system ? name : `custom.${name}`

      if (typeof defaultValue !== 'string') defaultValue = ''

      data.stepValues = data.stepValues.setIn(name.split('.'), values.getIn(name.split('.'), defaultValue))

      if (['image', 'file', 'video'].includes(type)) data.fileFields = data.fileFields.push(name)

      if (type === 'comment') {
        data.commentFields = data.commentFields.push(name)
        /* Check if video has been uploaded. Set that also if found */
        let videoName = `${name}_video`
        if (values.getIn(videoName.split('.'), ''))
          data.stepValues = data.stepValues.setIn(videoName.split('.'), values.getIn(videoName.split('.'), ''))
      }
    }

    return data
  }, data)
}

export default class Survey extends Component {
  handleSubmit = values => {
    let { handleStepSubmit, surveyFeatures, activeStep, surveyValues } = this.props
    let { fields = [] } = activeStep

    let processedFields = processFields(fields, mergeValues(surveyValues, values), surveyFeatures)

    let { stepValues, fileFields, commentFields } = preprocessValuesForSubmit(processedFields, values)

    return handleStepSubmit(stepValues, fileFields, commentFields)
  }

  render() {
    let {
      activeStep,
      activeStepKey,
      activeStepInitialValues,
      surveyInfo,
      surveySubmitting,
      surveyValues,
      surveyFeatures,
      surveyError,
      onResize,
      t,
      lang,
    } = this.props

    let { logo = false, company_name, title = false } = surveyInfo

    let { fields = [], submitText = {}, type, thanks = {}, stepNum } = activeStep

    if (type === 'thanks') return <SurveyThanks {...this.props} thanks={thanks} />

    let defaultSubmit = t('form.defaultSubmit')
    submitText = submitText[lang] || defaultSubmit

    return (
      <>
        <LangSwitcher {...this.props} />
        <div className="page-inner">
          <Form
            onSubmit={this.handleSubmit}
            className="survey-form"
            validation={generateValidator(fields)}
            values={activeStepInitialValues}
            t={t}
            key={`step-${activeStepKey}`}>
            {!activeStepKey && <UrlValues />}
            <div className="survey-form-inner">
              <SurveyHeader logo={logo} title={title || company_name} />
              {surveyError && (
                <div className="survey-error">
                  <div className="survey-error-message">
                    <i className="fa fa-exclamation-triangle" />
                    {t('error')}
                  </div>
                </div>
              )}
              <ProgressBar {...this.props} stepNum={stepNum} position="before" />
              <div className="survey-form-fields">
                <SurveyFields
                  fields={fields}
                  lang={lang}
                  t={t}
                  surveyValues={surveyValues}
                  features={surveyFeatures}
                  onResize={onResize}
                  info={surveyInfo}
                />
                {surveySubmitting ? (
                  <div className="survey-form-loader">
                    <i className="fa fa-spinner fa-spin" />
                  </div>
                ) : null}
              </div>
              <ProgressBar {...this.props} stepNum={stepNum} position="after" />
            </div>
            {surveyError && (
              <div className="survey-error">
                <div className="survey-error-message">
                  <i className="fa fa-exclamation-triangle" />
                  {t('error')}
                </div>
              </div>
            )}
            <SurveyAction
              text={submitText}
              icon={!surveySubmitting ? 'chevron-right' : 'spinner fa-spin'}
              disabled={surveySubmitting}
              surveyInfo={surveyInfo}
            />
          </Form>
        </div>
      </>
    )
  }
}
