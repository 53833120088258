class Session {
  session = {}

  constructor() {
    this.session = this.loadSession()
    if (!this.session) this.createSession()
  }

  loadSession() {
    try {
      let sessionData = localStorage.getItem('tmary')
      if (!sessionData) return

      sessionData = JSON.parse(sessionData)
      return sessionData
    } catch (err) {
      return
    }
  }

  createSession() {
    this.session = {}
    this.writeSession()
  }

  writeSession() {
    try {
      localStorage.setItem('tmary', JSON.stringify(this.session))
    } catch (err) {}
  }

  get(key, defaultValue = false) {
    return typeof this.session[key] !== 'undefined' ? this.session[key] : defaultValue
  }

  set(key, value) {
    this.session[key] = value
    this.writeSession()
  }
}

export default new Session()
