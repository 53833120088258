import React from 'react'

export default function SurveyClosed({ t }) {
  return (
    <div className="page-inner">
      <div className="survey-notfound">
        <div className="survey-notfound-inner">
          <i className="fa fa-window-close" />
          <div className="survey-notfound-text">{t('survey.closed', 'Survey is closed.')}</div>
        </div>
      </div>
    </div>
  )
}
