import React, { useEffect, useMemo } from 'react'
import SurveyStore from './survey/surveyStore'

export default function Root() {
  let locationQuery = useMemo(() => {
    return window.location.search
      .substr(1)
      .split('&')
      .reduce((mapped, str) => {
        let [key, value] = str.split('=')
        if (key) mapped[key] = value
        return mapped
      }, {})
  }, [])

  let {
    frame: frameId = false, // Frame id is added, if survey is embeded in a website
  } = locationQuery

  const view = frameId ? 'frame' : 'default'

  // Send resize info to parent frame
  const onResize = () => {
    try {
      parent.postMessage(
        {
          frameId,
          type: 'resize',
          attr: {
            height: document.body.offsetHeight,
          },
        },
        '*'
      )
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    document.body.classList.add(`view-${view}`)

    // If frame id is set, send
    if (frameId) {
      window.addEventListener('resize', e => onResize())
      onResize()
    }
  }, [])

  let pageClasses = ['page', `page--${view}`]

  const contextValue = {
    locationQuery,
    view,
    onResize,
  }

  return (
    <div className={pageClasses.join(' ')}>
      <div className="page-container">
        <SurveyStore {...contextValue} />
      </div>
    </div>
  )
}
