import React from 'react'
import { ThemeSelectIcon } from './checkbox'

export default function PrivacyPolicyField(props) {
  let { inputLabel: label = {}, lang, name, value, onChange, t, info = {}, validation = [], themeVar } = props
  let { privacy_policy, privacy_policy_organization } = info
  let isRequired = Array.isArray(validation) && validation.includes('required')

  let classes = ['q-checkbox']
  if (value) classes.push('q-checkbox--selected')

  label = typeof label === 'string' ? label : label[lang] || ''

  return (
    <div className={classes.join(' ')} title={isRequired ? t('validation.required') : ''}>
      <button
        className="q-checkbox-icon-wrapper"
        onClick={e => e.preventDefault() & onChange({ name, value: value !== true })}>
        <ThemeSelectIcon className="q-checkbox-icon" color={themeVar('primaryColor')}>
          {value ? <i className="fa fa-check" /> : null}
        </ThemeSelectIcon>
      </button>
      <div
        className="q-checkbox-label"
        onClick={e => (e.target.tagName === 'A' ? null : onChange({ name, value: value !== true }))}>
        {`${t('privacyPolicy.label')} ${privacy_policy_organization} `}
        {privacy_policy ? (
          <a href={privacy_policy} target="_blank">
            {t('publicConsent.privacyPolicy')}
          </a>
        ) : (
          t('publicConsent.privacyPolicy')
        )}
        .{isRequired ? <span className="q-checkbox-label-required">*</span> : null}
      </div>
    </div>
  )
}
