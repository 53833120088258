import React from 'react'
import './header.sass'

export default function SurveyHeader({ logo, title }) {
  return (
    <div className="survey-form-header">
      {logo ? (
        <div className="survey-form-header-logo">
          <img src={logo} />
        </div>
      ) : (
        <div className="survey-form-header-name">{title}</div>
      )}
    </div>
  )
}
