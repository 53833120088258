import { Map } from 'immutable'
import React from 'react'
import { getThemeVar, themeVariables } from '../theme'
import Checkbox from './checkbox'
import Checkboxes from './checkboxes'
import Comment from './comment'
import Content from './content'
import Dropdown from './dropdown'
import './field.sass'
import Fieldset from './fieldset'
import './fieldset.sass'
import File from './file'
import InputField from './input'
import MultiDropdown from './multidropdown'
import PrivacyPolicy from './privacypolicy'
import PublicConsent from './publicConsent'
import RadiosField from './radios'
import Range from './range'
import Stars from './stars'
import Textarea from './textarea'
import Video from './video'

const inputComponents = {
  nps: props => <Range {...props} rangeMin={0} rangeMax={10} defaultLabels={true} />,
  stars: props => <Stars {...props} />,
  range: Range,
  comment: Comment,
  textarea: Textarea,
  textfield: props => <InputField {...props} type="text" />,
  text: props => <InputField {...props} type="text" />,
  email: props => <InputField {...props} type="email" />,
  number: props => <InputField {...props} type="number" />,
  password: props => <InputField {...props} type="password" />,
  hidden: props => <InputField {...props} type="hidden" />,
  video: Video,
  checkbox: Checkbox,
  radios: RadiosField,
  dropdown: Dropdown,
  multidropdown: MultiDropdown,
  checkboxes: Checkboxes,
  image: props => <File {...props} fileType=".jpg,.jpeg,.png,.gif" />,
  content: Content,
  fieldset: Fieldset,
  privacy_policy: PrivacyPolicy,
  public_consent: PublicConsent,
}

export function QuestionField(props) {
  let {
    name,
    type,
    label,
    description,
    inputLabel = false,
    labelKey,
    descriptionKey,
    inputLabelKey,
    placeholder,
    prefix,
    suffix,
    processValue = false,
    options = [],
    t,
    lang,
    conditions = [],
    validation = [],
    fieldStyle = false,
    formContext,
    info = {},
    ...rest
  } = props

  let {
    formState: { values, errors = {} },
    formUpdate: onChange,
    formFileUpdate: onFileChange,
    formFileRemove: onFileRemove,
  } = formContext

  if (!Map.isMap(values)) values = Map()

  let value = values.getIn(name.split('.'), '')

  let isRequired = Array.isArray(validation) && validation.includes('required')

  let hasError = errors[name] !== undefined

  let input = inputComponents[type] || false

  let errorElements = hasError
    ? errors[name].map((text, key) => (
        <div className="q-field-error" key={key}>
          {text}
        </div>
      ))
    : []

  onChange = typeof processValue !== 'function' ? onChange : e => processValue(e, onChange)

  let classes = ['q-field', `q-field--${type}`]
  if (errorElements.length) classes.push('q-field--error')

  if (type === 'content') label = ''

  /**
   * Get label or use translation if key is given
   */
  if (typeof label !== 'string') label = label && label[lang] ? label[lang] : ''

  if (!label && labelKey) label = t(labelKey)

  /**
   * Get inputLabel or use translation if key is given
   */
  if (typeof inputLabel !== 'string') inputLabel = inputLabel && inputLabel[lang] ? inputLabel[lang] : ''

  if (!inputLabel && inputLabelKey) inputLabel = t(inputLabelKey)

  /**
   * Get description or use translation if key is given
   */
  if (typeof description !== 'string') description = description && description[lang] ? description[lang] : ''

  if (!description && descriptionKey) description = t(descriptionKey)

  const { theme = {} } = info

  const themeVar = key => getThemeVar(key, theme)

  let themeColors = {
    main: themeVar('primaryColor'),
    dark: themeVar('primaryColorDark'),
    light: themeVar('primaryColorLight'),
    alpha: themeVar('primaryColorAlpha'),
  }

  // If color is the default color, use highlight color instead for continuity
  if (themeColors.main === themeVariables.primaryColor)
    themeColors = {
      main: themeVar('highlightColor'),
      dark: themeVar('highlightColorDark'),
      light: themeVar('highlightColorLight'),
      alpha: themeVar('highlightColorAlpha'),
    }

  let inputProps = {
    ...rest,
    formContext,
    name,
    inputLabel,
    onChange,
    onFileChange,
    onFileRemove,
    value,
    values,
    placeholder,
    options,
    error: hasError,
    placeholder,
    validation,
    info,
    t,
    lang,
    theme,
    themeVar,
    themeColors,
  }

  if (label) classes.push('q-field--label')

  if (inputProps.style === 'footnote') classes.push('q-field--footnote')

  if (fieldStyle) classes.push(`q-field--${fieldStyle}`)

  let labelParts = label ? label.split('\n') : []
  let descriptionParts = description ? description.split('\n') : []

  return (
    <div className={classes.join(' ')}>
      {labelParts.length ? (
        <label
          className={`q-field-label q-field-label--${type}`}
          htmlFor={name}
          title={isRequired ? t('validation.required') : ''}>
          {labelParts.map((s, k) => (
            <div key={k}>
              {s}
              {k === labelParts.length - 1 && isRequired ? <span className="q-field-label-required">*</span> : null}
            </div>
          ))}
        </label>
      ) : null}
      {descriptionParts.length ? (
        <div className="q-field-description">
          {descriptionParts.map((s, k) => (
            <div key={k}>{s || ' '}</div>
          ))}
        </div>
      ) : null}
      <div className={`q-field-input q-field-input--${type}`}>
        {input ? React.createElement(input, inputProps) : `Input type ${type} not found`}
      </div>
      {errorElements.length ? <div className="q-field-errors">{errorElements}</div> : null}
    </div>
  )
}
