import styled from '@emotion/styled'
import React from 'react'
import './checkbox'

export const ThemeSelectIcon = styled.span`
  .q-checkbox:hover &,
  .q-radio:hover &,
  .q-checkbox-icon-wrapper:focus &,
  .q-radio-icon-wrapper:focus & {
    border-color: ${props => props.color};
  }

  .q-checkbox--selected &,
  .q-radio--selected & {
    background: ${props => props.color};
    border-color: ${props => props.color};
  }
`

export default function Checkbox({
  inputLabel: label = {},
  lang,
  style,
  name,
  value,
  onChange,
  link = false,
  validation = [],
  themeVar,
}) {
  let classes = ['q-checkbox']
  if (value) classes.push('q-checkbox--selected')

  let wrapperClasses = ['q-checkbox-wrapper']
  if (style) wrapperClasses.push(`q-checkbox-wrapper--${style}`)

  label = typeof label === 'string' ? label : label[lang] || ''

  let isRequired = Array.isArray(validation) && validation.includes('required')

  return (
    <div className={wrapperClasses.join(' ')}>
      <div className={classes.join(' ')}>
        <button
          className="q-checkbox-icon-wrapper"
          onClick={e => e.preventDefault() & onChange({ name, value: value !== true })}>
          <ThemeSelectIcon className="q-checkbox-icon" color={themeVar('primaryColor')}>
            {value ? <i className="fa fa-check" /> : null}
          </ThemeSelectIcon>
        </button>
        <div
          className="q-checkbox-label"
          onClick={e => (e.target.tagName === 'A' ? null : onChange({ name, value: value !== true }))}>
          {label}
          {isRequired ? <span className="q-checkbox-label-required">*</span> : null}
          {link ? (
            <div className="q-checkbox-label-link">
              <a href={link.url} target="_BLANK">
                {link.text}
              </a>
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  )
}
